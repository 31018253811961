export const calculateProgress = (formData) => {
  if (!formData) {
    console.warn('calculateProgress: formData est undefined ou null');
    return 0;
  }

  const coordonnees = formData.coordonnees || {};
  const adresse = formData.adresse || {};

  const isValidString = (str) => typeof str === 'string' && str.trim().length > 0;

  try {
    const fields = {
      nom: isValidString(coordonnees.nom) && coordonnees.nom.trim().length >= 2,
      prenom: isValidString(coordonnees.prenom) && coordonnees.prenom.trim().length >= 2,
      email: isValidString(coordonnees.email) && 
             coordonnees.email.includes('@') && 
             coordonnees.email.includes('.') &&
             coordonnees.email.length >= 5,
      genre: typeof formData.genre === 'string' && formData.genre.trim().length > 0,
      dateNaissance: formData.dateNaissance instanceof Date || 
                    (typeof formData.dateNaissance === 'string' && Date.parse(formData.dateNaissance)),
      adresse: isValidString(adresse.rue),
      ville: isValidString(adresse.ville),
      codePostal: isValidString(adresse.codePostal) && 
                  /^\d{5}$/.test(adresse.codePostal),
      telephone: isValidString(formData.telephone) && 
                /^[+\d\s-]{10,}$/.test(formData.telephone.trim())
    };

    const totalFields = Object.keys(fields).length;
    if (totalFields === 0) return 0;

    const completedFields = Object.values(fields).filter(Boolean).length;
    const progress = (completedFields / totalFields) * 100;

    return Math.min(Math.max(Math.round(progress * 100) / 100, 0), 100);

  } catch (error) {
    console.error('Erreur dans calculateProgress:', error);
    return 0;
  }
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};