import React, { useEffect, useState } from 'react';
import pikkoBlocked from '../../styles/img/account/pikkoBlocked.svg';
import { AccountAvatar } from '../card/AccountAvatar';

const CircularProgress = ({ percentage = 60, user,screen,animate = true }) => {
  const radius = 54;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 200) * circumference;
  const [particles, setParticles] = useState([]);
  const [showExplosion, setShowExplosion] = useState(false);

  // Gestion de l'explosion
  useEffect(() => {
    if (percentage >= 100 && !showExplosion) {
      setShowExplosion(true);
      createExplosion();
    }
  }, [percentage]);

  const createExplosion = () => {
    const particleCount = 12;
    const newParticles = [];

    for (let i = 0; i < particleCount; i++) {
      const angle = (i * 360) / particleCount;
      const distance = 100;
      const tx = Math.cos((angle * Math.PI) / 180) * distance;
      const ty = Math.sin((angle * Math.PI) / 180) * distance;

      newParticles.push({
        id: i,
        style: {
          '--tx': `${tx}px`,
          '--ty': `${ty}px`,
          left: '50%',
          top: '50%',
          animation: `particleExplosion 5s ease-out forwards`,
          animationDelay: `${i * 0.05}s`
        }
      });
    }

    setParticles(newParticles);
    
    // Nettoyer les particules après l'animation
    setTimeout(() => {
      setParticles([]);
    }, 1000);
  };

  return (
    <div className={`progress-container ${percentage >= 100 ? 'completed' : ''}`}>
      {percentage >= 100 && !screen ? (
        <>
          <AccountAvatar user={user} />

          {/* {animate && particles.map(particle => (
            <div
              key={particle.id}
              className="explosion-particle"
              style={particle.style}
            />
          ))} */}
        </>
      ) : (
        <>
          <svg className="background" width="120" height="120">
            <circle cx="60" cy="60" r="40" fill="#52544A" opacity={0.7} />
          </svg>
          <svg className="progress" width="120" height="120">
            <circle
              cx="60"
              cy="60"
              r="40"
              fill="none"
              stroke="#5c5f5c"
              strokeWidth="8"
              strokeLinecap="round"
              strokeDasharray={circumference}
              strokeDashoffset="0"
              transform="rotate(-90 60 60)"
              style={{ transition: "stroke-dashoffset 0.5s" }}
            />
          </svg>
          <svg className="progress" width="120" height="120">
            <defs>
              <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#FFDB5A" />
                <stop offset="50%" stopColor="#FF7B9C" />
                <stop offset="100%" stopColor="#FF4E83" />
              </linearGradient>
            </defs>
            <circle
              cx="60"
              cy="60"
              r="40"
              fill="none"
              stroke="url(#progressGradient)"
              strokeWidth="8"
              strokeLinecap="round"
              strokeDasharray={circumference}
              strokeDashoffset={offset}
              transform="rotate(-90 60 60)"
              style={{ transition: "stroke-dashoffset 0.5s" }}
            />
          </svg>
          <div className="CircularProgress_icon">
            <img src={pikkoBlocked} alt="pikkoBlocked" />
          </div>
          <div className="percentage">{percentage?.toFixed(0)}%</div>
        </>
      )}
    </div>
  );
};

export default CircularProgress;