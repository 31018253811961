import React, { forwardRef, useState, useMemo, useEffect } from "react";
import BackButton from "../../components/button/BackButton";
import { SmsConnexion } from "./phoneNumberLogin/SmsConnexion";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-international-phone/style.css";
import { auth } from "../../firebase/firebaseConfig";
import onNumberSignUp, {
  onOTPVerify,
} from "./phoneNumberLogin/HandleConnectNumber";
import LoaderWithText from "../../components/loader/LoaderWithText";
import { notifyError, notifySuccess, notifyWarning } from "../../utils/Notify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosInterceptors from "../../axios/useAxios";
import GetUserPhoneInfo from "./phoneNumberLogin/GetUserPhoneInfo";
import {
  savePhoneNumberUser,
  saveUser,
  saveUserWithFid,
} from "../../actions/userActions";
import {
  changePassword,
  checkIfUserPhoneExists,
} from "../../actions/userPhoneAction";
import { PhonePasswordSignIn } from "./phoneNumberLogin/phonePasswordSignIn";
import { PhonePasswordSignUp } from "./phoneNumberLogin/phonePasswordSignUp";
import { ACCOUNT, HOME, INVITATION, NUMBER_SCREEN, SCAN } from "../../constants/routes";
import Swal from "sweetalert2";
import pikkoLogo from "../../styles/img/account/pikkoProfile.svg";
import googleLogo from "../../styles/img/google.svg";
import mailLogo from "../../styles/img/mail.svg";
import { CountrySelector } from "react-international-phone";
import PrimaryButton from "../../components/button/PrimaryButton";
import { isAppPWA } from "../../utils/helper-functions";
import dracu_pikko from "../../styles/img/halloween/dracu-pikko.svg";
import { useTheme } from "styled-components";
export const GetPhoneRedirectInfo = async (
  auth,
  setLoadingConnexion,
  dispatch,
  axiosInstance,
  cartItems,
  navigate,
  result,
  password,
  action,
  number,
  setLockUntil,
  setError
) => {
  try {
    auth =
      action === "signup"
        ? auth
        : {
            currentUser: {
              phoneNumber: number,
            },
          };
    const data = await GetUserPhoneInfo(auth, result, password);

    if (data.newResult.profile.fidCardNum) {
      const fidCardNum = data.newResult.profile.fidCardNum;
      dispatch(
        saveUserWithFid(
          data.newResult.profile,
          axiosInstance,
          cartItems,
          fidCardNum,
          navigate
        )
      );
    } else {
      const result = await dispatch(
        savePhoneNumberUser(
          data.newResult.profile,
          axiosInstance,
          cartItems,
          navigate,
          action,
          setLockUntil,
          setError
        )
      );
    }

    setLoadingConnexion(false);
  } catch (error) {
    setLoadingConnexion(false);
    console.error(
      "Une erreur s'est produite lors de l'authentification avec Google:",
      error
    );
  }
};
const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
const NumberScreen = ({allowOtherLoginMethod}) => {
  const InputRef = React.useRef();
  const theme= useTheme();
  const [step, setStep] = React.useState(1);
  const [number, setNumber] = React.useState("");
  const [finalNumber, setFinalNumber] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [code, setCode] = React.useState("");
  const isValid = isPhoneValid(finalNumber);
  const [loading, setLoading] = React.useState(false);
  const [loadingSubmitNumber, setLoadingSubmitNumber] = React.useState(false);
  const [loadingOtp, setLoadingOtp] = React.useState(false);
  const [result, setResult] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [dialCode, setDialCode] = React.useState("33");
  const [checkPassword, setCheckPassword] = React.useState("");
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [country, setCountry] = useState("fr");
  const axiosInstance = useAxiosInterceptors();
  const { cartItems } = useSelector((state) => state.cart);
  const [isExist, setIsExist] = React.useState(false);
  const [lockUntil, setLockUntil] = React.useState(null);
  const {userNotConnected} = useLocation().state || false;
  const [viewOtherMethod, setViewOtherMethod] = React.useState(false);
  const { prevPage } = useLocation().state || "";

  // hooks
  const isPWA = isAppPWA();

  const onNumberChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value) && value.length <= 20) {
      setNumber(value);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (user && user._id) {
      if(prevPage === SCAN) {
        navigate(SCAN);
      }
      else {
        navigate(ACCOUNT);
      }
    }
    if(window.location.pathname === '/config'){
      localStorage.setItem('PREV_LOGIN_PAGE', 'config')
    }
    if(userNotConnected){
      localStorage.setItem("isNewPWA", "true");
    }
    setCode("");
    setPassword("");
    setCheckPassword("");
  }, []);
  const onsubmitNumber = async (type, id) => {
    if (finalNumber.length < 10) {
      notifyWarning("Numéro invalide");
      return;
    }

    setLoadingSubmitNumber(true);

    const check = await checkIfUserPhoneExists(axiosInstance, finalNumber);
    if (check.success) {
      setLoadingSubmitNumber(false);
      setIsExist(true);
      setStep(3);
      return;
    } else if (check.serverError) {
      notifyError("Erreur lors de la vérification du numéro");
    } else {
      setIsExist(false);
      const lastAttemptDate = localStorage.getItem("lastAttemptDate");
      const currentTime = new Date().getTime();

      if (lastAttemptDate && currentTime - lastAttemptDate < 120000) {
        // 120000 ms = 2 minutes
        setStep(2);
        setLoadingSubmitNumber(false);
        return;
      }

      if (isValid) {
        const result = await onNumberSignUp(
          auth,
          finalNumber,
          setStep,
          type,
          id
        );
        if (result?.success) {
          // Sauvegarder la date à laquelle on a reçu le résultat dans le local storage
          localStorage.setItem("lastAttemptDate", currentTime);
          setStep(2);
        } else {
          if (result?.error === "Firebase: Error (auth/too-many-requests).")
            notifyError("Trop de requêtes.. Réessaye plus tard");
          else notifyError("Erreur lors de l'envoi du code");
        }
      } else {
        notifyWarning("Numéro invalide");
      }
    }

    setLoadingSubmitNumber(false);
  };

  const buttonref = React.useRef();

  const passwordValidation = async (action) => {
    setLoading(true);

    if (!isExist) {
      if (password !== checkPassword) {
        notifyError("Les mots de passe ne correspondent pas");
        setLoading(false);
        return;
      }
      if (password.length < 6) {
        notifyError("Le mot de passe doit contenir au moins 6 caractères");
        setLoading(false);
        return;
      }
    }

    if (action === "forgot") {
      const result = await changePassword(
        axiosInstance,
        finalNumber,
        password,
        setLockUntil
      );
      if (result.success) {
        notifySuccess("Mot de passe modifié");
        setForgotPassword(false);
        setStep(3);
      } else {
        notifyError("Erreur lors de la modification du mot de passe");
      }
      setLoading(false);
      return;
    }

    const results = await GetPhoneRedirectInfo(
      auth,
      setLoading,
      dispatch,
      axiosInstance,
      cartItems,
      navigate,
      result.user,
      password,
      action,
      finalNumber,
      setLockUntil,
      setPasswordError
    );

    if (results) {
      notifySuccess("Connexion réussie");
    }
    setPassword("");

    checkIfLock();
  };
  const checkIfLock = () => {
    const lockUntil = localStorage.getItem("lockUntil");
    if (lockUntil) {
      const lockUntilDate = new Date(lockUntil);
      if (lockUntilDate > new Date()) {
        setLockUntil(lockUntilDate);
      } else {
        localStorage.removeItem("lockUntil");
        setLockUntil(null);
      }
    } else {
      setLockUntil(null);
    }
  };

  useEffect(() => {
    checkIfLock();
    console.log("lockUntil", lockUntil);
  }, []);
  useEffect(() => {
    async function checkOtp() {
      if (code.length === 6) {
        setLoading(true);
        setLoadingOtp(true);
        const result = await onOTPVerify(auth, code);
        if (result.user && result.success) {
          // notifySuccess("Code correct")
          setResult(result);

          if (forgotPassword) {
            setStep("forgot");
          } else {
            setStep(3);
          }
        } else {
          notifyError("Code incorrect");
        }
        setCode("");
        setLoading(false);
        setLoadingOtp(false);
      }
    }

    checkOtp();
  }, [code]);
  const numberRef = React.useRef();
  useEffect(() => {
    let newNumber = number;
    if (newNumber[0] === "0") {
      newNumber = newNumber.slice(1);
    }
    setFinalNumber("+" + dialCode + newNumber);
  }, [number, dialCode]);

  const handleNewPassword = (value) => {
    setPassword(value);
  };

  const handleNewCheckPassword = (value) => {
    setCheckPassword(value);
  };

  const handleSignInPassword = (value) => {
    setPassword(value);
  };
  const swicthConnectionMethod = () => {
    if(prevPage === INVITATION){
      navigate(ACCOUNT , {state: {prevPage: NUMBER_SCREEN, numberSreenPrevPage: INVITATION}})
    }
    else{
      navigate(ACCOUNT , {state: {prevPage: NUMBER_SCREEN}})
    }
  }
  return (
    <div className="smsLogin">
      {(user.loading || loadingOtp) && (
        <LoaderWithText text="Chargement en cours" />
      )}

      <div className="inputBackButton">
        {(!isPWA || (isPWA && step !== 1))  && (

          <BackButton
            iconOnly
            isLogoVisible={true}
            onClick={() => {
              switch (step) {
                case 1:
                  if(prevPage){
                    navigate(prevPage, {state: {prevPage: NUMBER_SCREEN}})
                  }
                  else {
                    navigate(HOME);
                  }

                  break;
                case 2:
                  setStep(1);
                  break;
                case 3:
                  if (isExist) {
                    setStep(1);
                  } else {
                    setStep(2);
                  }
                  break;
                case "forgot":
                  setStep(3);
                  break;
                default:
                  break;
              }
            }}
          />
        )}

        {step === 1 ? (
          <div className="headerSmsConnexion">
            {/* <div className="titleLogo">
              <img src={pikkoLogo} alt="pikkoLogo" />
            </div> */}
            <div className="circle-gradient">
              { theme?.logos?.loginLogo && <img src={theme.logos.loginLogo.image} alt="loginLogo" className="dracu-pikko" />}
            </div>
            <div className="instructions">
              <h1>Pikko Compte</h1> Tape ton numéro de téléphone !
            </div>
            <div className="inputSms">
              <div className="PhoneCountryCode">
                <CountrySelector
                  selectedCountry={country}
                  onSelect={({ iso2, dialCode }) => {
                    setCountry(iso2);
                    setDialCode(dialCode);
                  }}
                />
              </div>
              <div className="phoneInputGroup">
                <div>+{dialCode}</div>
                <input
                  autoComplete="tel"
                  value={number}
                  onChange={onNumberChange}
                  ref={numberRef}
                  type="tel"
                  name="tel"
                  className="inputNumber"
                  id="inputNumber"
                />
              </div>
            </div>
            <div
              id="recaptcha-container"
              onClick={(e) => onsubmitNumber("invisible", buttonref.current.id)}
              ref={buttonref}
              disabled={loadingSubmitNumber}
              className="primary-button"
            >
              {loadingSubmitNumber ? "Chargement..." : "Continuer"}
            </div>
            {allowOtherLoginMethod && (
              <button className="otherMethod" onClick={swicthConnectionMethod}>
                Autre méthode de connexion
              </button>
            )}

          </div>
        ) : step === 2 ? (
          <SmsConnexion
            code={code}
            setCode={setCode}
            number={finalNumber}
            auth={auth}
            setStep={setStep}
            setForgotPassword={setForgotPassword}
            setLoading={setLoading}
          />
        ) : step === 3 ? (
          isExist ? (
            <PhonePasswordSignIn
              handleSignInPassword={handleSignInPassword}
              valider={() => passwordValidation("signin")}
              setStep={setStep}
              number={finalNumber}
              setForgotPassword={setForgotPassword}
              auth={auth}
              passwordError={passwordError}
              lockUntil={lockUntil}
              setLockUntil={setLockUntil}
              setLoading={setLoading}
              setPasswordError={setPasswordError}
            />
          ) : (
            <PhonePasswordSignUp
              password={password}
              setPassword={setPassword}
              checkPassword={checkPassword}
              setCheckPassword={setCheckPassword}
              setStep={setStep}
              valider={() => passwordValidation("signup")}
              backFunction={() => setStep(2)}
              handleNewPassword={handleNewPassword}
              handleNewCheckPassword={handleNewCheckPassword}
            />
          )
        ) : (
          step === "forgot" && (
            <PhonePasswordSignUp
              password={password}
              setPassword={setPassword}
              checkPassword={checkPassword}
              setCheckPassword={setCheckPassword}
              setStep={setStep}
              valider={() => passwordValidation("forgot")}
              backFunction={() => setStep(3)}
              handleNewPassword={handleNewPassword}
              handleNewCheckPassword={handleNewCheckPassword}
            />
          )
        )}
      </div>
    </div>
  );
};
export default NumberScreen;
