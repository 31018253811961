import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import { configure } from 'scandit-sdk';
import { isAppPWA } from '../../utils/helper-functions';
import * as Sentry from '@sentry/react';

window.isScanditConfigured = false;

const loadScanditLibrary = async () => {
  try {
    await SDCCore.configure({
                              licenseKey: process.env.REACT_APP_SCANDIT_KEY,
                              libraryLocation: new URL(
                                'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.25.0/build/engine/',
                                document.baseURI
                              ).toString(),
                              moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
                            });
    window.isScanditConfigured = true;
    return true;
    console.log('Scandit library loaded successfully v6.x');
  } catch (error) {
    console.error('Error loading Scandit library:', error);
    Sentry.captureException(error);
    throw error;
  }
};

const loadScanner = async () => {
  const licenseKey = process.env.REACT_APP_SCANDIT_KEY;
  await configure(licenseKey, {
    engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/',
  })
    .then(() => {
      console.log('configure scanner success v5.x');
      return true;
    })
    .catch((error) => {
      alert(error);
      Sentry.captureException(error);
      throw error;
    });
};

const isPWA = isAppPWA();

const ScanditInit = async () => {
  return new Promise(async (resolve, reject) => {
    if (isPWA) {
      // load scanner-test
      await loadScanditLibrary().then(() => {
        resolve()
      }).catch((err) => {
        console.log('loadScanditLibrary ERROR :: ', err)
        Sentry.captureException(err);
        reject(err)
      });
    } else {
      // load scanner
      await loadScanner().then(() => {
        resolve()
      }).catch((err) => {
        console.log('loadScanner ERROR :: ', err)
        Sentry.captureException(err);
        reject(err)
      });
    }
  })
}

export default ScanditInit;
