import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react";
import eye from "../../../styles/lottie/eye.json";
import eyeOff from "../../../styles/lottie/eye-off.json";
import Swal from "sweetalert2";
import onNumberSignUp from "./HandleConnectNumber";
import { notifyError, notifySuccess } from "../../../utils/Notify";
import OTPInput from "otp-input-react";
import OtpInput from "react18-input-otp";

import passWordLogo from "../../../styles/img/account/passwordLogo.svg";
import passWordLogoError from "../../../styles/img/account/passwordLogoError.svg";

export const PhonePasswordSignIn = ({
  setPasswordError,
  valider,
  setStep,
  number,
  auth,
  handleSignInPassword,
  setForgotPassword,
  lockUntil,
  setLockUntil,
  passWordError,
  setLoading,
}) => {
  const captchaRef = useRef(null);
  const [password, setPassword] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isRecaptchaState, setIsRecaptchaState] = useState(false);
  useEffect(() => {
    if (lockUntil) {
      const interval = setInterval(() => {
        const now = new Date();
        const timeLeft = new Date(lockUntil) - now;
        if (timeLeft <= 0 || isNaN(timeLeft)) {
          setTimeRemaining(0);
          setLockUntil(null);
          clearInterval(interval);
        } else {
          setTimeRemaining(Math.ceil(timeLeft / 1000)); // in seconds
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setTimeRemaining(null);
    }
  }, [lockUntil]);
  const onPasswordInputChange = (value) => {
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPassword(value);
      handleSignInPassword(value);
    }
  };

  const onClickPasswordForgotten = () => {
    Swal.fire({
      title: "Mot de passe oublié",
      text: "Veux-tu réinitialiser ton mot de passe ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00000000",
      cancelButtonColor: "#00000000",
      confirmButtonText:
        '<button className=" w-full p-4 rounded-2xl bg-red-200 text-red-700 ">Réinitialiser</button>',
      showLoaderOnConfirm: true,
      cancelButtonText:
        '<button className=" w-full p-4 rounded-2xl bg-slate-900 text-slate-200 ">Annuler</button>',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsRecaptchaState(true);
        const result = await onNumberSignUp(
          auth,
          number,
          setStep,
          "normal",
          captchaRef.current.id
        );
        if (result.success) {
          setStep(2);
          setForgotPassword(true);
        } else {
          notifyError("Erreur lors de l'envoi du code");
          console.log(result);
        }
        setIsRecaptchaState(false);
      }
    });
  };
  useEffect(() => {
    if (timeRemaining > 0 && passWordError?.length > 0) {
      setPasswordError("");
    }
  });
  useEffect(() => {
    if (password.length === 6 || password.length === 0) {
      if (document.querySelector(".OtpInput")) {
        document.querySelector(".OtpInput").firstChild?.focus();
      }
    }
    if (password.length === 6) {
      valider();
      setPassword("")
    }
  }, [password]);
  return (
    <>
      <div className="phone-password-container">
        {!isRecaptchaState ? (
          <>
            <img
              src={passWordError?.length > 0 ? passWordLogoError : passWordLogo}
              alt="pikkoLogo"
              className="pikkoLogo-phone-screen"
            />
            <div>
              <h1>Mot de passe</h1>
              <OtpInput
                className="OtpInput"
                value={password}
                separator={<span> </span>}
                separateAfter={1}
                onChange={onPasswordInputChange}
                numInputs={6}
                isInputNum={true}
                disabled={false}
                shouldAutoFocus={true}
                inputStyle="password-input"
                isInputSecure={true}
                type="password"
                // autoComplete={true}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  autoFocus: true,
                }}
              />
            </div>
            <div
              onClick={onClickPasswordForgotten}
              className="forgottenPassword"
            >
              Mot de passe oublié ?
            </div>
            {passWordError?.length > 0 && timeRemaining <= 0 && (
              <p className="error">{passWordError}</p>
            )}
            {timeRemaining > 0 && (
              <p className="retryPassword error">
                Compte bloqué temporairement. Réessaye dans{" "}
                {Math.floor(timeRemaining / 60)}mn :{" "}
                {timeRemaining % 60 > 9
                  ? timeRemaining % 60
                  : "0" + (timeRemaining % 60)}
                s ou réinitialise ton mot de passe
              </p>
            )}
          </>
        ) : (
          <>
            <p className="recaptcha-titre">
              Aide nous à garantir une expérience sécurisée en complétant le
              reCAPTCHA qui s'affiche.
            </p>
            <div id="recaptcha" ref={captchaRef}></div>
          </>
        )}
      </div>
    </>
  );
};
