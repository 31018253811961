import darkPikko from '../../styles/img/account/darkPikko.svg';
import pikkoAvatar from '../../styles/img/logo/pikkoAvatar.json';
import Lottie from 'lottie-react';
export const AccountAvatar=({user})=>{

    return(
        user.completed?
        <div className="w-[40px]">
            <div className="dark-pikko">
                <img src={darkPikko} alt="avatar"/>
            </div>
        </div>
        :
        <div className="w-[40px] completed">
            <Lottie animationData={pikkoAvatar} style={{width:'100%',height:'100%'}} loop={true} autoplay={true}/>
        </div>
        
    )
}