import React, {memo, useEffect, useState} from 'react';
import useAxiosInterceptors from '../../axios/useAxios';
import { getUserEmail } from '../../utils/StringUtils';
import { getOrderAmount } from '../../utils/PriceUtils';
import { useDispatch, useSelector } from 'react-redux';
import { log } from 'qrcode/lib/core/galois-field';
import { successPaidOrder } from '../../actions/cartActions';
import { addUsedPromo } from '../../actions/userActions';
import { notifyDoubleCallback, notifyError } from '../../utils/Notify';
import promo from '../../components/promo';
import { isTRissuerExcluded } from '../../utils/Validator';
import {useCheckout} from "../../context/checkoutProvider";

const PayzenInit = ({
                      onPayzenInitialized, onPayzenError, onPaymentLoading, order, promoLoaded, setPayzenToken,
                      setTRpopup
                    }) => {
  const { dispatchCheckout } = useCheckout();

  const dispatch = useDispatch()
  const axiosInstance = useAxiosInterceptors();
  const user = useSelector((state) => state.user);
  const [errorTR, setErrorTR] = useState(false);

  const dispatchData = (data, payzenSavedCard) => {
    dispatch(successPaidOrder(data));
    dispatch(addUsedPromo(user, axiosInstance, order, !!payzenSavedCard.pm, payzenSavedCard));
  };

  const updateOrder = (email, paymentIntent, paymentMethod, payzenSavedCard) => {
    axiosInstance.put(`/orders/${order._id}/pay`, {
      user, paymentIntent, paymentMethod, email, creditCard: payzenSavedCard
    })
                 .then((response) => {
                   if (!user || !user.email) {
                     user.email = email
                   }
                   dispatchData(response.data, payzenSavedCard);
                 })
                 .catch((error) => {
                   console.error({ error });
                 })
  };
  const initializePayzenListeners = async (email, token) => {
    await window.KR.setFormConfig({ formToken: token, 'kr-language': 'fr-FR' });
    dispatchCheckout({ type: 'PAYZEN_MODULE_LOAD', payload: true })
    window.KR.onTransactionCreated((event) => {
      // console.log(event)
      // if(event?.clientAnswer?.transactions?.[0]?.errorMessage === 'payment refused' && event?.clientAnswer?.transactions?.[0]?.transactionDetails?.cardDetails?.issuerName !== 'IMAGOR S.A..'){
      //   // notifyError('Nous n\'acceptons pas les titres restaurant via Apple Pay')
      //   setErrorTR(true)
      //   setTRpopup(true)
      // }else{
      //   setErrorTR(false)
      // }
    });
    window.KR.onError(error => {
      onPaymentLoading(false)
      console.log('WINDOW KR ERROR', error)
      const excludedErrorCodes = ['CLIENT_101'];
      if (error.errorCode && !excludedErrorCodes.includes(error.errorCode)) {
        if (error.errorCode === 'CLIENT_309' && error.field === 'cardHolderMail') onPayzenError('Informations email incorrectes')
        else if (error?.paymentMethod !== 'APPLE_PAY') onPayzenError(error.detailedErrorMessage);
        else if (error?.metadata?.answer?.clientAnswer?.transactions?.[0]?.transactionDetails?.cardDetails?.issuerName && isTRissuerExcluded(error?.metadata?.answer?.clientAnswer?.transactions?.[0]?.transactionDetails?.cardDetails?.issuerName)) setTRpopup(true)
        else notifyError(error.errorMessage);
      }
    })
    window.KR.smartForm.onClick(() => {
      onPaymentLoading(true)
    })

    window.KR.onSubmit(async (response) => {
      console.log('KR ONSUBMIT', response)
      const isPaid = response?.clientAnswer?.orderStatus === 'PAID';

      if (!isPaid || response.clientAnswer.transactions.length === 0) {
        console.error('An error occured during payzen payment');
        return;
      }
      console.log(response)
      const transaction = response.clientAnswer.transactions[0];
      const paymentMethodToken = transaction.paymentMethodToken;
      const cardHolderPan = transaction.transactionDetails.cardDetails.cardHolderPan;
      const effectiveBrand = transaction.transactionDetails.cardDetails.effectiveBrand;
      const isWalletPayment = !!(transaction.transactionDetails.wallet && transaction.transactionDetails.wallet.length > 0);
      const isApplePay = transaction.transactionDetails.wallet === 'APPLE_PAY';
      const last4digit = cardHolderPan.slice(-4);
      email = email || response.clientAnswer.customer.email || ''
      const payzenSavedCard = !isApplePay && paymentMethodToken
        ? {
          pm: paymentMethodToken,
          last4: last4digit,
          brand: effectiveBrand.toLowerCase(),
          isPayzen: true
        }
        : {
          brand: effectiveBrand.toLowerCase(),
          last4: last4digit,
        };
      console.log('PAYZEN', payzenSavedCard)
      const paymentIntent = {
        id: response.clientAnswer.orderDetails.orderId,
        status: 'succeeded',
      };
      const paymentMethod = isWalletPayment ? transaction.transactionDetails.wallet.toLowerCase() : 'cb';
      await updateOrder(email, paymentIntent, paymentMethod, payzenSavedCard);

    })

    window.KR.onFormValid((event) => {
      onPayzenError('')
    })
  }

  const initLib = (token) => {
    return new Promise(async (resolve, reject) => {
      const existingScript = document.querySelector(`script[src="https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js"]`);

      if (existingScript) {
        await existingScript.parentNode.removeChild(existingScript);
      }

      const script = document.createElement('script');
      script.src = 'https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
      script.type = 'text/javascript';
      // script.async = true;
      // script.defer = true;
      script.setAttribute('kr-public-key', process.env.REACT_APP_PAYZEN_PUBLIC_KEY);
      script.setAttribute('kr-language', 'fr-FR');
      // script.setAttribute('kr-hide-debug-toolbar', true);
      script.onload = async (event) => {
        console.log('Payzen script loaded:', event);
        await initializePayzenListeners(user?.email, token)
        resolve();
      };
      script.onerror = (error) => {
        console.error('Error loading Payzen script:', error);
        reject(error);
      };
      document.body.appendChild(script);
    });
  };

  const initializePayzen = () => {
    const email = getUserEmail(user);
    console.log('INIT PAYZEN ORDER AMOUNT', order)
    axiosInstance.post('/payzen/getToken',
                       {
                         amount: getOrderAmount(order),
                         orderId: order._id,
                         email
                       })
                 .then(async (response) => {
                   const token = response.data;
                   localStorage.setItem('token', JSON.stringify(token));
                   // setPayzenToken(token)
                   dispatchCheckout({ type: 'PAYZEN_SET_TOKEN', payload: token })
                   await initLib(token.paymentToken);
                 })
                 .catch((error) => {
                   console.log({ error })
                   // window.location.reload();
                 });
  };
  useEffect(() => {
    if (promoLoaded) initializePayzen();
  }, [promoLoaded]);

  return null;
};

export default React.memo(PayzenInit);
