import Lottie from 'lottie-react';
import React, { forwardRef, useState } from 'react';
import eye from '../../../styles/lottie/eye.json'
import eyeOff from '../../../styles/lottie/eye-off.json'
import check from '../../../styles/lottie/check.json'
import uncheck from '../../../styles/lottie/uncheck.json'
import loadingLottie from '../../../styles/lottie/loading.json'
import cgu from '../../../pdf/CGU.pdf'
import confidentialite from '../../../pdf/politique.pdf'
import HandleCreateEmail from './HandleCreateEmail';
import { auth } from '../../../firebase/firebaseConfig.js'
import Swal from 'sweetalert2';
import { sendEmailVerification } from 'firebase/auth';
import { emailRegex } from '../../../utils/StringUtils';
import HandleConnectEmail from './HandleConnectEmail';
import { authenticate } from '../../../actions/authenticateActions';
import { saveUser, saveUserWithFid } from '../../../actions/userActions';
import { notifyWarning } from '../../../utils/Notify';
import useAxiosInterceptors from '../../../axios/useAxios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const AccountCreation = ({
                           email,
                           setEmail,
                           showPassword,
                           handleTogglePasswordVisibility,
                           password1,
                           setPassword1,
                           createAccountLoading,
                           setCreateaccountLoading,
                           user,
                           onblurScrolltoTop,
                           connexion,
                           setState
                         }, ref) => {
  const [seePasswordFaible, setSeePasswordFaible] = useState(false);
  const [password2,setPassword2] = useState('');
  const [condition, setCondition] = useState(false);
  const { cartItems } = useSelector(state => state.cart);
  const axiosInstance = useAxiosInterceptors()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onpasswordFocus = () => { // TODO : à tester
    console.log('focus');
    window.scrollTo(0, 0);
    if (document.getElementById('page_account')) {
      document.getElementById('page_account').scrollTop = 0;
    }
  }

  const handlecondition = () => {
    setCondition(!condition)
  }

  const onpasswordBlur = () => {
    console.log('blur');
    window.scrollTo(0, 0);
    if (document.getElementById('page_account')) {
      document.getElementById('page_account').scrollTop = 0;
    }
  }

  const onpasswordverifFocus = () => {
    setSeePasswordFaible(true);
    if (document.getElementById('page_account')) {
      document.getElementById('page_account').scrollTop = 0;
    }
  }

  const onpasswordverifBlur = () => {
    setSeePasswordFaible(false)
    window.scrollTo(0, 0)
    if (document.getElementById('page_account')) {
      document.getElementById('page_account').scrollTop = 0;
    }
  }
  const isPwStrong = (pw) => {
    // return /[a-z]/.test(pw) && /[A-Z]/.test(pw) &&
    //   pw.match(/([0-9])/) &&
    //   pw.match(/([!,%,&,@,#,$,^,*,?,_,~])/) &&
    //   pw.length > 7;
    return pw.length > 7
  }

  const handleCreate = async () => {
    setCreateaccountLoading(true);

    if (localStorage.getItem('newuser')) {
      localStorage.removeItem('newuser');
    }

    if (password1 === password2 && password1.length > 0) {
      try {
        const data = await HandleCreateEmail(auth, user, email, password1)
        if (data) {
          sendEmailVerification(auth.currentUser).then(async () => {
            Swal.fire({
                        title: 'Un mail de vérification a été envoyé  !',
                        icon: 'info',
                        showConfirmButton: false,
                        html: '<div className=\'my-2 flex gap-8 geomanistBold\'><button id=\'continuer\' className=\'geomanistBold p-4 payer w-full rounded-md text-[1.5rem]\' id=\'resendEmail\'>Continuer</button></div>',
                      })
            // const data = await HandleConnectEmail(auth, email, password1, user)
            // authenticate(axiosInstance, email, data.result.user.accessToken)
            //   .then(() => {
            //       dispatch(saveUser(data.newResult, axiosInstance, cartItems, navigate));
            //   })
            //   .catch((error) => {
            //     if (error.response.data.errorCode === 'USER_FID_CARD_ALREADY_USED') {
            //       notifyWarning('Oups', 'L\'email ne correspond à la carte de fidélité', 'Ok');
            //     } else {
            //       notifyWarning('Oups', 'Une erreur est survenue lors de ta connexion.', 'Ok');
            //     }
            //     console.error('Authenticate', error);
            //   })
            if (document.getElementById('continuer')) {
              document.getElementById('continuer').addEventListener('click', () => {
                setCreateaccountLoading(false);
                Swal.close();
                setState('signIn');

                // if (connexion) {
                //   connexion();
                // }
              })
            }
          })
        } else {
          setCreateaccountLoading(false);
        }
      } catch (e) {
        setCreateaccountLoading(false);
        console.log(e);
      }
    } else {
      setCreateaccountLoading(false);
      if (!password1.length > 0) alert('Il faut renseigner un mot de passe ! 😉');
      else alert('Les mots de passe ne correspondent pas 😅');
    }
  }

  return (

    <>
      <div className="inputGroup flex flex-col items-start w-full">
        <label htmlFor="email" className="promo_label ">Email</label>
        <input
          className="user_mail_input w-full"
          type="email"
          id="email"
          placeholder="Ton email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={onblurScrolltoTop}
          required
        />
      </div>
      {!email.length > 0 || !email.toLowerCase().match(emailRegex) && (
        <div className="text-red-600 text-[1.3rem]">Email invalide</div>
      )}
      <div className="inputGroup w-full flex flex-col items-start">
        <label htmlFor="passwordCreate" className="promo_label">Mot de passe</label>
        <input onFocus={onpasswordFocus} onBlur={onpasswordBlur} className=""
               type={showPassword ? 'text' : 'password'} id="passwordCreate" placeholder="Créer un mot de passe"
               onChange={(event) => setPassword1(event.target.value)} />
        <button
          className="absolute top-[70%] right-8 transform -translate-y-1/2"
          onClick={handleTogglePasswordVisibility}
        >
          {showPassword ?
            <Lottie
              animationData={eye}
              loop={false}
              autoplay={true}
              style={{ width: '20px', height: '20px' }}
            />
            :
            <Lottie
              animationData={eyeOff}
              loop={false}
              autoplay={true}
              style={{ width: '20px', height: '20px' }}
            />
          }
        </button>
      </div>
      {/*{seePasswordFaible ? /[a-z]/.test(password1) && /[A-Z]/.test(password1) && password1.match(/([0-9])/) && password1.match(/([!,%,&,@,#,$,^,*,?,_,~])/) && password1.length > 7 ? (*/}
      {password1.length > 7 ? (
        <></>
      ) : (
        <div className="text-red-600 text-[1.3rem]">
          {/*Le mot de passe ne respecte pas tous les critères*/}
          8 caractères minimum
        </div>
      )
        // : <></>
      }
      <ul className="list-unstyled text-[1.4rem]">
        {/*<li className="">*/}
        {/*  <span*/}
        {/*    className={`flex items-center justify-start ${/[a-z]/.test(password1) && /[A-Z]/.test(password1) ? 'text-[#16c72e]' : ''}`}>*/}
        {/*    <Lottie*/}
        {/*      animationData={/[a-z]/.test(password1) && /[A-Z]/.test(password1) ? check : uncheck}*/}
        {/*      loop={false}*/}
        {/*      autoplay={true}*/}
        {/*      style={{ width: '20px', height: '20px' }}*/}
        {/*    />*/}
        {/*    &nbsp;Minuscule &amp; Majuscule*/}
        {/*  </span>*/}
        {/*</li>*/}
        {/*<li className="">*/}
        {/*  <span*/}
        {/*    className={`flex items-center justify-start ${password1.match(/([0-9])/) ? 'text-[#16c72e]' : ''}`}>*/}
        {/*  <Lottie*/}
        {/*    animationData={password1.match(/([0-9])/) ? check : uncheck}*/}
        {/*    loop={false}*/}
        {/*    autoplay={true}*/}
        {/*    style={{ width: '20px', height: '20px' }}*/}
        {/*  />*/}
        {/*    &nbsp;Chiffres (0-9)*/}
        {/*  </span>*/}
        {/*</li>*/}
        {/*<li className="">*/}
        {/*  <span*/}
        {/*    className={`flex items-center justify-start ${password1.match(/([!,%,&,@,#,$,^,*,?,_,~])/) ? 'text-[#16c72e]' : ''}`}>*/}
        {/*    <Lottie*/}
        {/*      animationData={password1.match(/([!,%,&,@,#,$,^,*,?,_,~])/) ? check : uncheck}*/}
        {/*      loop={false}*/}
        {/*      autoplay={true}*/}
        {/*      style={{ width: '20px', height: '20px' }}*/}
        {/*    />*/}
        {/*    &nbsp;Caractère spécial (!@#$%^&*)*/}
        {/*  </span>*/}
        {/*</li>*/}
        <li className="">
            <span className={`flex items-center justify-start ${password1.length > 7 ? 'text-[#16c72e]' : ''}`}>
                  <Lottie
                    animationData={password1.length > 7 ? check : uncheck}
                    loop={false}
                    autoplay={true}
                    style={{ width: '20px', height: '20px' }}
                  />
              &nbsp;Au moins 8 caractères
              </span>
        </li>
      </ul>
      <div className="inputGroup w-full flex flex-col items-start mt-4">
        <input onFocus={onpasswordverifFocus} onBlur={onpasswordverifBlur}
               type={showPassword ? 'text' : 'password'} id="passwordCreate2"
               placeholder="Confirmation de mot de passe" onChange={(event) => setPassword2(event.target.value)}
               value={password2} />
        <button
          className="absolute top-[48%] right-8 transform -translate-y-1/2"
          onClick={handleTogglePasswordVisibility}
        >
          {showPassword
            ? (
              <Lottie
                animationData={eye}
                loop={false}
                autoplay={true}
                style={{ width: '20px', height: '20px' }} // Adjust the width and height as needed
              />
            )
            : (
              <Lottie
                animationData={eyeOff}
                loop={false}
                autoplay={true}
                style={{ width: '20px', height: '20px' }} // Adjust the width and height as needed
              />
            )
          }
        </button>
      </div>
      <div className="conditionDutilisation text-start mt-6">
        <div className="flex">
          <input type="checkbox" name="Conditions" id="cbx" checked={condition} />
          <label onClick={handlecondition} htmlFor="cbx" className={'conditioncheckbox cbx'}></label>
        </div>
        <div style={{ fontFamily: 'Open Sans', fontWeight: 400 }}>
          <label onClick={handlecondition} htmlFor="conditions" className="conditionlabel">J'accepte les
            <a href={cgu} target="_blank" className="cgu"> Conditions Générales d'Utilisation de PikkoPay</a>
          </label>
        </div>
      </div>
      {password1 !== password2 && password2.length > 0 && (
        <div className="text-red-600 text-[1.3rem]">
          Les mots de passe ne correspondent pas
        </div>
      )}
      {!createAccountLoading ? <button
          className={`${!password1.length > 0 || password1 !== password2 || !isPwStrong(password1) || !email.toLowerCase().match(emailRegex) || !condition ? 'disabled_button payer w-full p-6 my-4 text-3xl geomanistBold' : 'payer w-full p-6 my-4 text-3xl geomanistBold bottom-btn-shadow'}`}
          onClick={handleCreate}
          disabled={!password1.length > 0 || password1 !== password2 || !isPwStrong(password1) || !email.toLowerCase().match(emailRegex)}>
          Créer mon compte
        </button> :
        <div className="w-full flex justify-center items-center "><Lottie
          animationData={loadingLottie}
          loop={true}
          autoplay={true}
          style={{ width: '100px', height: '100px' }} // Adjust the width and height as needed
        /></div>
      }

    </>
  );
};

export default forwardRef(AccountCreation);
