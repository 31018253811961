import React, { useEffect, useState } from 'react';
import { convertSecondsToTime } from '../../utils/helper-functions';
import clock from '../../styles/img/account/clock.svg';
import trophy from '../../styles/img/account/trophy.svg';

export const Statistics = ({ user }) => {
  const [usageCount, setUsageCount] = useState(0);
  const [personalRecord, setPersonalRecord] = useState({ minutes: 0, seconds: 0 });
  const ranking = user?.topPourcentage ? user?.topPourcentage.toFixed(1) : undefined

  useEffect(() => {
    if (user) {
      if (user.ordersCount && user.ordersCount > 0) {
        setUsageCount(user.ordersCount);
      }
      if (user.bestTime) {
        const formattedTime = convertSecondsToTime(user.bestTime);
        setPersonalRecord(formattedTime);
      }
    }
  }, [user]);

  return (
    <div className="statistics">
      {/* Utilisation */}
      <div className="stat-item a large">
        <h2 className="order-count-h2">{usageCount}</h2>
        <p>Nombre d'utilisation</p>
        <small>depuis la création du compte</small>
      </div>

      {/* Record personnel */}
      <div className="stat-item b small">

        <div className="record-content">
          <h2>{personalRecord.minutes} <span>m</span> {personalRecord.seconds} <span>s</span></h2>
          <div className="icon-clock">
            <img src={clock} alt="clock" />
          </div>
        </div>
        <p>Record personnel</p>
        <small>avec Pikkopay</small>
      </div>

      {/* Classement */}
      <div className="stat-item c small">
        <p className="transform -translate-y-4">Classement</p>
        <div className="ranking-content">

          <div className="icon-trophy">
            <img src={trophy} alt="trophy" />
          </div>
          <h2 className="top-user-h2">
            <span
            className="top-user-text">
                Top
            </span> {`${ranking ? Math.floor(ranking) : 0}`}%
            {ranking ? (
          <>
            {ranking && ranking < 11 && (
              <span className='top-user-description'>Tu fais partie du top {Math.floor(ranking)}% des utilisateurs les plus actifs ! 😎</span>
            )}
            {ranking && ranking >= 11 && ranking < 40 && (
              <span className='top-user-description'>Seulement {Math.floor(ranking) - 1}% des utilisateurs sont plus actifs que toi ! 💪</span>
            )}
            {ranking && ranking >= 40 && ranking < 70 && (
              <span className='top-user-description'>Améliore ton score en faisant plus de PikkoPay ! 🤗</span>
            )}
            {ranking && ranking >= 70 && (
              <span className='top-user-description'>Améliore ton score en faisant plus de PikkoPay ! 🤗</span>
            )}
          </>
        ) : (
          <span className='top-user-description'
          >Pas assez de donnée pour le moment 🧐</span>
        )}
            </h2>
        </div>
       


      </div>
    </div>
  );
};
