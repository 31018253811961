export const isAppPWA = () => {
  const isPWA =
    window.navigator.standalone === true ||
    window.matchMedia("(display-mode: standalone)").matches;

  return isPWA;
  // return true
};

export const convertSecondsToTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  //retourner un code html
  return {
    minutes,
    seconds: remainingSeconds,
  }
}
