import React, { forwardRef, useEffect, useImperativeHandle, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dell from '../../styles/img/dell.svg';
import { getOrderAmount } from '../../utils/PriceUtils';
import useAxiosInterceptors from '../../axios/useAxios';
import { successPaidOrder } from '../../actions/cartActions';
import { addUsedPromo, updatePayzenCards } from '../../actions/userActions';
import { formatPriceWithEuro, getUserEmail } from '../../utils/StringUtils';
import PayzenInit from './PayzenInit';

const PayzenCreditCard = ({
                            onCheckboxChange, paymentMethod, errorMessage, resetPaymentMethod, order,
                            promoLoaded, setPayzenToken, setTRpopup
                          }, ref) => {
  const axiosInstance = useAxiosInterceptors();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const payzenCreditCards = useSelector((state) => state.user.payzenCreditCards) || {}
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const [payzenError, setPayzenError] = useState('');

  const [isContentVisible, setIsContentVisible] = useState(false)
  const [saveCard, setSaveCard] = useState(false);
  const [payzenTokenForm, setPayzenTokenForm] = useState(undefined);
  const [payzenLoading, setPayzenLoading] = useState(true);

  const isChecked = paymentMethod === 'cb';

  const handleCheckboxChange = () => {
    onCheckboxChange();
  };

  const deletePayzenCreditCard = (payzenCreditCards) => {
    dispatch(updatePayzenCards(payzenCreditCards));
    resetPaymentMethod();
    alert('Rafraichissement de la page pour mettre à jour les paiements');
    window.location.reload();
    // setIsContentVisible(false);
  };

  const handleDeletePayzenCreditCard = () => {
    if (!user._id) {
      deletePayzenCreditCard([]);
      return;
    }

    axiosInstance.put('/payzen/delete-card',
                      {
                        paymentMethodToken: payzenCreditCards[0].pm,
                        userId: user._id
                      })
                 .then((response) => {
                   deletePayzenCreditCard(response.data.payzenCreditCards);
                 })
                 .catch((error) => {
                   deletePayzenCreditCard(payzenCreditCards[0])
                   console.log({ error })
                 });
  }

  const handleSubmit = async () => {
    const email = getUserEmail(user);
    axiosInstance.post('/payzen/pay',
                       {
                         amount: getOrderAmount(cartItems),
                         orderId: cartItems._id,
                         storeId: cartItems.storeId,
                         userEmail: email,
                         user: user,
                         payzenCreditCards: payzenCreditCards[0]
                       })
                 .then((response) => {
                   console.log(response)
                   if (response.data.status === 'succeeded') {
                     dispatch(successPaidOrder(response.data));
                     dispatch(addUsedPromo(user, axiosInstance, cartItems));
                   } else {
                     setPayzenError('Paiement échoué')
                   }
                 })
                 .catch((error) => {
                   console.log({ error })
                   // setPayzenError('Paiement échoué')
                   error?.response?.data?.payzenResponse?.transactions?.[0]?.errorMessage ?
                    errorMessage(error?.response?.data?.payzenResponse?.transactions?.[0]?.errorMessage) :
                    errorMessage('Paiement échoué')

                 });
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }))

  const handleShowCreditCard = (e) => {
    handleCheckboxChange();
    if (e.target.checked) {
      setIsContentVisible(true)
    } else {
      setIsContentVisible(false)
    }
  };

  const handleSaveCard = (state) => {
    let checkbox = document.querySelector('.checkbox');
    if (state.target.checked) {
      checkbox.classList.add('checked_svg');
    } else {
      checkbox.classList.remove('checked_svg');
    }
    setSaveCard(!saveCard);
  };

  useEffect(async () => {
    setIsContentVisible(isChecked);
    if (isChecked) {
      //   await window?.KR?.setFormConfig({
      //                                     formToken: JSON.parse(localStorage.getItem('token')).formToken,
      //                                     'kr-language': 'fr-FR'
      //                                   });
      // } else {
      //   await window?.KR?.setFormConfig({
      //                                     formToken: JSON.parse(localStorage.getItem('token')).paymentToken,
      //                                     'kr-language': 'fr-FR'
      //                                   });

    }
  }, [isChecked]);

  const onPayzenInitialized = (token) => {
    setPayzenTokenForm(token);
    setPayzenLoading(false);
  };

  const onPayzenError = (errorMessage) => {
    setPayzenError(errorMessage);
  };

  const onPaymentLoading = (event) => {
    if (document.querySelector('.kr-payment-button')) {
      if (event === true) {
        document.querySelector('.kr-payment-button').innerHTML = `<p>Chargement ...</p> `;
      } else {
        document.querySelector('.kr-payment-button').innerHTML = `<p>Payer maintenant</p> ${formatPriceWithEuro(getOrderAmount(cartItems))}`;
      }
    }
  }

  return (
    <>
      <PayzenInit onPayzenInitialized={onPayzenInitialized} onPayzenError={onPayzenError}
                  onPaymentLoading={onPaymentLoading} order={order} promoLoaded={promoLoaded}
                  setPayzenToken={setPayzenToken} setTRpopup={setTRpopup} />

      {payzenCreditCards && payzenCreditCards[0]?.last4 ? (
        <div className="flex items-center formcarte text-black px-8 pr-14 w-full">
          <div className="flex w-full" onClick={handleCheckboxChange}>
            <input
              type="checkbox"
              checked={isChecked}
              id="choicecb"
              onChange={() => {
                handleCheckboxChange();
                // toggleAccordion();
              }}
            />
            <label className="cbchecklabel mr-4"></label>
            <div className="flex flex-row items-end gap-8 items-center">
              <img className="h-10 rounded-[5px] mr-2 " src={payzenCreditCards[0].img} />
              <div className="text-xl">.... {payzenCreditCards[0].last4} </div>
            </div>
          </div>
          <button
            onClick={handleDeletePayzenCreditCard}
            className="deletepayment"
            style={{ marginLeft: 'auto' }}
          >
            <img src={dell} alt="" />
          </button>
        </div>

      ) : (
        <div>
          <div
            onClick={handleCheckboxChange}
            className={`flex items-center formcarte text-black px-8 pr-14 w-full ${isContentVisible ? 'slide-down-btn open' : ''}`}
          >
            <div className="cbandlabel flex  w-full">
              <div className="flex  w-[70%] items-center">
                <input
                  id="cbCheckbox"
                  name="cbCheckboxview"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleShowCreditCard}
                />
                <label className="cbchecklabel mr-4" onClick={handleCheckboxChange} />
                <p className="text-[16px]" style={{ fontFamily: 'geomanistRegular' }}>Carte bancaire</p>
              </div>
              <div className="cbimg flex gap-2 no-wrap">
                <img
                  className="h-16 rounded-[5px]"
                  src="https://storage.googleapis.com/pikkopay_images/webapp/payment/swile.png"
                />
                <img
                  className="h-16"
                  src="https://storage.googleapis.com/pikkopay_images/webapp/payment/visa.png"
                />
                <img
                  className="h-16"
                  src="https://storage.googleapis.com/pikkopay_images/webapp/payment/mastercard.png"
                />
              </div>
            </div>
          </div>
          <div>
            <div
              id="cbpopup" style={{ fontFamily: 'Open Sans' }}
              className={` slide-down-transition ${
                isContentVisible
                  ? 'slide-down-content visible '
                  : 'slide-down-content'
              }`}
            >
              <input className="mr-4" id="cbcheckbox" type="checkbox" checked={saveCard} onChange={handleSaveCard} />
              <div className="kr-smart-form" kr-payment-method="CARDS"></div>

              {payzenError && (
                <div className="kr-custom-error">{payzenError}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default forwardRef(PayzenCreditCard);







