import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { findUser } from "../../actions/userActions";
import useAxiosInterceptors from "../../axios/useAxios";
import menuBurger from "../../styles/img/menu-burger.png";
import doubleArrow from "../../styles/img/arrow/double-arrow.png";
import { ACCOUNT, REWARD, UPDATE_USER } from "../../constants/routes";
import SecondaryButton from "../button/SecondaryButton";
import PikkoPointContainer from "../pikko-box/PikkoPointContainer";
import buttonBackBlack from "../../styles/img/button/button_back_black.svg";
import headerBackground from "../../styles/img/background/header-background.png";
import blackGift from "../../styles/img/blackGift.svg";
import { AccountAvatar } from "./AccountAvatar";
import CircularProgress from "../loader/CircularProgress";
import { calculateProgress } from "../../utils/userInfoProgress";
const PikkoPointHeader = ({
  user,
  isMainPage,
  onClick,
  onBack,
  notEuroVersion,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInterceptors();
  const [userData, setUserData] = useState("");
  const [formData, setFormData] = useState({
    coordonnees: {
        nom: user.nom || '',
        prenom: user.prenom || '',
        email: user.email || ''
    },
    genre:user.gender || '',
    dateNaissance: user.birthDate || '',
    adresse: {
        rue: user.address || '',
        ville: user.city || '',
        codePostal: user.zipCode || ''
    },
    telephone: user.phoneNumber || ''

});
  const handleSettings = () => {
    console.log("handle settings");
  };

  const convertPikkoPoints = () => {
    navigate(REWARD);
  };

  const onBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(ACCOUNT);
    }
  };
  useEffect(() => {
    setFormData({
        coordonnees: {
            nom: user.nom || '',
            prenom: user.prenom || '',
            email: user.email || ''
        },
        genre:user.gender || '',
        dateNaissance: user.birthDate || '',
        adresse: {
            rue: user.address || '',
            ville: user.city || '',
            codePostal: user.zipCode || ''
        },
        telephone: user.phoneNumber || ''

    });
    setUserData(user.prenom || user.nom || "");

      }, [user]);

  return (
    <div className="pikko-point-header">
      <div className="header-content">
        <div className="action-button">
          {isMainPage ? (
            <div onClick={onClick}>
              <img src={menuBurger} alt="chevronBottom" />
            </div>
          ) : (
            <div onClick={onBackClick}>
              <img src={buttonBackBlack} alt="buttonBackBlack" />
            </div>
          )}
        </div>
        <div className="hello-user">
          Hello {userData}
        </div>
       {calculateProgress(formData)<100 &&
        <div className="text_secondary mb-6">
          Complète ton profil
        </div>}
        <div className="account-avatar" onClick={() => navigate(UPDATE_USER)}>
          <CircularProgress percentage={calculateProgress(formData)} user={user} animate={false} />

        </div>

      </div>
    </div>
  );
};

export default PikkoPointHeader;
