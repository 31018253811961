import React, { forwardRef } from "react";
import coco from '../styles/img/feuillecocotier.svg';
import PrimaryButton from './button/PrimaryButton';
import logoBrand from "../styles/img/logo-brand.png";
import { IoMdArrowForward } from 'react-icons/io';

const SwipperTuto = forwardRef(({ close, TutoCurrentIndex, setTutoCurrentIndex, view, slides }, ref) => {
  const nextSlide = () => {
    const newIndex = (TutoCurrentIndex + 1) % slides.length;
    setTutoCurrentIndex(newIndex);
  };
  return (
    <div className={`slider-container ${view?'visible':''}`}>
      <div className="sliderTuto bg-gradient1" >
      <div className="logo-brand-tuto ">
        <img src={logoBrand} alt="logo-brand" />
      </div>
      <img src={coco} alt="" className="coco-leaf-right" />
        <img src={coco} alt="" className="coco-leaf-left" />
        {slides.map((slide, index) => (
          <div
            key={index}
            onTouchStart={(e) => {
              //checker si jai toucher plus a droite ou a gauche
              //si a droite je fais next slide
              //si a gauche je fais prev slide
              if (e.touches[0].clientX > window.innerWidth / 2) {
                if (TutoCurrentIndex === slides.length - 1)return;
                nextSlide();
              }
              else {
                if (TutoCurrentIndex === 0) return;
                const newIndex = (TutoCurrentIndex - 1) % slides.length;
                setTutoCurrentIndex(newIndex);
              }
            }}
            style={{

                opacity: TutoCurrentIndex === index ? 1 :0 ,
                zIndex: TutoCurrentIndex === index ? 1 : 0,
                right: TutoCurrentIndex === index ? 0 :TutoCurrentIndex > index ? '100%' : '-100%',
                filter: TutoCurrentIndex === index ? 'blur(0px)' : 'blur(2px)',
            }}
            className={index === TutoCurrentIndex ? 'slideTuto active' : 'slideTuto'}
          >
            {slide}
          </div>
        ))}
         <div className="dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={index === TutoCurrentIndex ? 'dot active' : 'dot'}
            onClick={() => setTutoCurrentIndex(index)}
          ></span>
        ))}
      </div>
      </div>
     {
        TutoCurrentIndex<slides.length -1 ?
         <button className="next"  onClick={nextSlide}>
            Suivant <IoMdArrowForward size={26}/>
         </button>
         :
         <div className="fixed_bottom">
            <PrimaryButton label={"J’ai compris !"} onClick={close} />
         </div>


     }
    </div>
  );
})

export default SwipperTuto;
