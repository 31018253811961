import React from "react";
import checkscan from '../styles/img/checkscansvg1.svg';
import arrow from '../styles/img/arrow/arrow.svg';
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import scancheckLottie from '../styles/lottie/scancheck.json';

const SuccessNotif = ({actioner}) => {

    const navigate = useNavigate();

    return (
        <div className={"blurback active"}>
        <div className={"cartefinalscancheckcontenair animbackshadow  flex flex-col bg-gradient1"} onClick={actioner}>
        <div className="gap-4 items-start justify-between flex flex-col">
          <div className="flex justify-between">
              <img className="max-h-[50dvh]" src={checkscan} alt="" srcset="" />    
              <div className="text_scan_column gap-20">
              <div className="paymentsuccess text-2xl"><h3>Paiement réussi</h3></div>
              <div className="lastStep">
                <Lottie animationData={scancheckLottie} style={{width: "130px", height: "130px"}} />
                <h2> 
                  Pour sortir
                </h2>
                <h4>Scanne le code de sortie</h4>            
              </div>
            </div> 
          </div>
          <p className="px-4 w-[90%] text-xl ml-20" style={{fontFamily: "Open Sans"}}><span className="opacity-[0.5]">Il te suffit de</span><span className="opacity-[1]"> passer par la sortie Express </span><span className="opacity-[0.5]"> et de </span><span className="opacity-[1]"> scanner le QR code de sortie </span> <span className="opacity-[0.5]">pour avertir l’hôte(sse)</span>.</p>
        </div>
        <button className="ok_let_scan payer py-6 flex items-center justify-center text-3xl gap-2 notif" onClick={actioner}style={{fontFamily: "geomanistmedium"}}>J'ai compris 
          {/* <img src={arrow} alt="next" /> */}
          </button>
        </div>
      </div>

    )

}

export default SuccessNotif;
